<template>
    <div class="min-h-screen flex flex-col">
        <HeaderImpersonate></HeaderImpersonate>

        <MenuApp class="grow-0"></MenuApp>

        <Home-pop-up></Home-pop-up>

        <div class="mb-auto grow w-full grow-custom">
            <slot :from="from"></slot>
        </div>

        <FooterApp class="grow-0"></FooterApp>

        <ChatFloating></ChatFloating>
    </div>
</template>

<script>
import HeaderImpersonate from "~/components/HeaderImpersonate.vue";
import HomePopUp from "~/pages/components/HomePopUp.vue";

export default {
    name: "DefaultLayout",
    components: {HomePopUp, HeaderImpersonate},
    data: () => ({
        from: 'maslow',
    })
}
</script>

<style scoped lang="scss">
.grow-custom {
    @apply flex;

    & > :deep(div){
        @apply w-full;
    }
}
</style>
